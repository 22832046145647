import { Link } from "gatsby"
import React from "react"
import { Col, Row } from "react-grid-system"
import styled from "styled-components"
import { Heading } from "./typography"
import Img from "gatsby-image"

const CategoriesBlock = ({ title, categories }) => (
  <Wrapper>
    <Row>
      <Col>
        <Heading>{title}</Heading>
      </Col>
    </Row>
    <Row>
      {categories.map(category => (
        <Col md={6} key={category.id}>
          <Link to={category.slug + `/ `}>
            <Img fluid={category.heroImage.fluid} />
            <Title>{category.title}</Title>
          </Link>
        </Col>
      ))}
    </Row>
  </Wrapper>
)

const Wrapper = styled.div`
  & > * {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Title = styled.div`
  position: relative;
  top: -2rem;
  left: 2rem;
  background: white;
  padding: 1rem 2rem;
`

export default CategoriesBlock
