import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-grid-system"
import { HeadingSmall } from "./typography"
import { LinkButton } from "./button"
import Img from "gatsby-image"

const CallToAction = ({ title, slug, name, backgroundImage }) => (
  <Wrapper>
    <CustomBackground>
      <Img fluid={backgroundImage.fluid} />
    </CustomBackground>
    <Container>
      <Row>
        <Col>
          <Content>
            <HeadingSmall>{title}</HeadingSmall>
            <LinkButton to={slug}>{name}</LinkButton>
          </Content>
        </Col>
      </Row>
    </Container>
  </Wrapper>
)
const Wrapper = styled.div`
  position: relative;
  padding-top: 4rem;
  padding-bottom: 10rem;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  & > * {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const CustomBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  .gatsby-image-wrapper {
    height: 100%;
  }
`

export default CallToAction
