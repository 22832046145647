import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Row, Col } from "react-grid-system"
import { useApplication } from "../hooks/useApplication"
import { Link } from "gatsby"
import UrlScheme from "./urlScheme"

const NavApplications = ({ lang }) => {
  const links = useApplication().filter(obj => {
    return obj.locale === lang
  })

  return (
    <Row>
      <Col>
        <Links>
          {links.map(link => (
            <Link
              to={
                link.locale === "it"
                  ? `/${UrlScheme["it"].applications}/${link.slug}/`
                  : `/${link.locale}/${UrlScheme[link.locale].applications}/${
                      link.slug
                    }/`
              }
              key={link.id}
              activeClassName="active"
            >
              {link.title}
            </Link>
          ))}
        </Links>
      </Col>
    </Row>
  )
}

const Links = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    color: black;
    text-decoration: none;
    &:hover {
      color: ${DesignTokens.colors.primary[500]};
    }
    &.active {
      color: ${DesignTokens.colors.primary[500]};
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: -1rem;
        width: 4px;
        height: 100%;
        background: ${DesignTokens.colors.primary[500]};
      }
    }
  }
`

export default NavApplications
