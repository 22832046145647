import { Link } from "gatsby"
import styled from "styled-components"
import DesignTokens from "./designTokens.js"

const LinkButton = styled(Link)`
  display: inline-block;
  padding: ${DesignTokens.spacing[3]} ${DesignTokens.spacing[5]};
  text-decoration: none;
  border: none;
  text-transform: uppercase;
  color: ${DesignTokens.colors.white};
  background: ${DesignTokens.colors.primary[500]};
  font-weight: 500;
  &:hover {
    background: ${DesignTokens.colors.primary[500]};
  }
  &:active {
    background: ${DesignTokens.colors.primary[500]};
  }
`

const ExternalLinkButton = styled.a`
  display: inline-block;
  padding: ${DesignTokens.spacing[3]} ${DesignTokens.spacing[5]};
  text-decoration: none;
  border: none;
  color: ${DesignTokens.colors.white};
  background: ${DesignTokens.colors.primary[500]};
  font-weight: 500;
  &:hover {
    background: ${DesignTokens.colors.primary[500]};
  }
  &:active {
    background: ${DesignTokens.colors.primary[500]};
  }
`

const LinkButtonSecondary = styled(LinkButton)`
  color: ${DesignTokens.colors.white};
  background: ${DesignTokens.colors.secondary[500]};
  &:hover {
    background: ${DesignTokens.colors.secondary[500]};
  }
  &:active {
    background: ${DesignTokens.colors.secondary[500]};
  }
`
const ExternalLinkButtonSecondary = styled(ExternalLinkButton)`
  color: ${DesignTokens.colors.white};
  background: ${DesignTokens.colors.secondary[500]};
  &:hover {
    background: ${DesignTokens.colors.secondary[500]};
  }
  &:active {
    background: ${DesignTokens.colors.secondary[500]};
  }
`
const LinkButtonTertiary = styled(LinkButton)`
  color: ${DesignTokens.colors.primary[500]};
  background: white;
  &:hover {
    color: white;
    background: ${DesignTokens.colors.tertiary[500]};
  }
  &:active {
    color: white;
    background: ${DesignTokens.colors.tertiary[500]};
  }
`
const ExternalLinkButtonTertiary = styled(ExternalLinkButton)`
  background: ${DesignTokens.colors.tertiary[500]};
  color: ${DesignTokens.colors.secondary[500]};
  &:hover {
    background: ${DesignTokens.colors.tertiary[500]};
  }
  &:active {
    background: ${DesignTokens.colors.tertiary[500]};
  }
`

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  a {
    margin: 0 ${DesignTokens.spacing[2]};
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`

export {
  LinkButton,
  ButtonGroup,
  LinkButtonSecondary,
  LinkButtonTertiary,
  ExternalLinkButton,
  ExternalLinkButtonTertiary,
  ExternalLinkButtonSecondary
}
