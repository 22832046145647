const UrlScheme = {
  it: {
    applications: "applicazioni",
    products: "prodotti",
  },
  en: {
    applications: "applications",
    products: "products",
  },
  de: {
    applications: "anwendungen",
    products: "produkte",
  },
}

export default UrlScheme
