import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import IntroBlock from "../components/introBlock"
import { Container } from "react-grid-system"
import { Grid } from "theme-ui"
import CategoriesBlock from "../components/categoriesBlock"
import HeroHeader from "../components/heroHeader"
import NavApplications from "../components/navApplications"
import CallToAction from "../components/callToAction"
import { getApplicationPath } from "../components/pathUtils"
import { HelmetDatoCms } from "gatsby-source-datocms"

const ApplicationPage = ({ data: { page }, pageContext, location }) => {
  const lang = pageContext.locale
  const i18nPaths = page._allSlugLocales.map(locale => {
    return {
      locale: locale.locale,
      value: getApplicationPath(page, locale.locale),
    }
  })

  return (
    <Layout
      lang={pageContext.locale}
      title={page.title}
      location={location}
      i18nPaths={i18nPaths}
    >
      <HelmetDatoCms seo={page.seoMetaTags} >
        <html lang={lang} />
      </HelmetDatoCms>
      <HeroHeader
        title={page.title}
        subtitle={page.subtitle}
        heroImage={page.heroImage}
      />
      <Wrapper>
        <Container>
          <Grid columns={[1, 1, "1fr 3fr"]} gap={[32, 64]}>
            <NavApplications lang={lang} />
            <Content>
              <IntroBlock text={page.text} />
              <CategoriesBlock
                title={page.listName}
                categories={page.categories}
              />
            </Content>
          </Grid>
        </Container>
      </Wrapper>
      <CallToAction
        title={page.callToAction.title}
        backgroundImage={page.callToAction.backgroundImage}
        slug={page.callToAction.slug}
        name={page.callToAction.name}
      />
    </Layout>
  )
}

const Wrapper = styled.div`
  padding-top: 6rem;
  padding-bottom: 6rem;
`

const Content = styled.div`
  & > * {
    margin-bottom: 6rem;
    &:last-child {
      margin-bottom: 0;
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 2rem;
    }
  }
`

export default ApplicationPage

export const query = graphql`
  query ApplicationPageQuery($slug: String!, $locale: String!) {
    page: datoCmsApplicationPage(slug: { eq: $slug }, locale: { eq: $locale }) {
      ...AllApplicationSlugLocales
      title
      slug
      subtitle
      text
      heroImage {
        fluid(maxWidth: 1920, imgixParams: { fm: "jpg" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      id
      locale
      listName
      categories {
        id
        title
        slug
        application {
          slug
        }
        heroImage {
          fluid(maxWidth: 520, imgixParams: { fm: "jpg" }) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
      callToAction {
        title
        backgroundImage {
          fluid(maxWidth: 1920, imgixParams: { fm: "jpg" }) {
            ...GatsbyDatoCmsFluid
          }
        }
        slug
        name
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
  fragment AllApplicationSlugLocales on DatoCmsApplicationPage {
    _allSlugLocales {
      locale
      value
    }
  }
`
